import React from 'react';

// Component
import Layout from '../components/layout';
import Hero from '../components/hero';

const ThankYouGoogle = () => (
  <Layout title="Thank You Google">
    <Hero
      title="Thank you!"
      description="A member of our team will be reaching out to you as soon as possible. Due to a high inquiry volume, please allow for up to one business day to hear back from us."
      location={[
        { id: '1', value: 'Homepage', location: '/' },
        { id: '2', value: '', location: '' },
      ]}
      showButton
    />
  </Layout>
);

export default ThankYouGoogle;
